import { Box, Center, Flex, HStack, VStack, Button, useBreakpointValue } from "@chakra-ui/react";

import { FC } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading2 } from "src/lib/renderer/elements/headings";

import { applyNbsp } from "src/lib/renderer/utils";
import { SvgOrImg } from "src/components/SvgOrImg";
import { navigate } from "gatsby";

const BlockPricingLight: FC<Gatsby.PageBlockPricingLightFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  if ((items?.length ?? 0) <= 0) {
    throw Error();
  }

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const {
    content
  } = primary;

  const itemsFirstGroup = items?.length ? items.filter((i) => !i?.is_second_row) : [];
  const itemsSecondGroup = items?.length ? items.filter((i) => !!i?.is_second_row) : [];

  const renderItem = (
    item: NonNullable<Gatsby.PageBlockPricingLightFragment["items"]>[number],
    hasAtLeast3Items: boolean
  ) => {

    if (!item) {
      return null;
    }

    const {
      badge_color: badgeColor,
      badge_icon: badgeIcon,
      badge_label: badgeLabel,
      title,
      subtitle_prefix: subtitlePrefix,
      subtitle_price: subtitlePrice,
      subtitle_suffix: subtitleSuffix,
      description,
      link,
    } = item;

    const imageFile = badgeIcon?.document?.data?.image_file;

    const renderButton = () => {
      return (
        <Button
          fontSize={{ base: "font-20", md: "font-18" }}
          lineHeight="base"
          fontWeight="normal"
          variant="ghost"
          padding="0px"
          marginLeft="0px"
          marginRight="0px"
          minHeight={{ base: "inherit", md: "56px" }}
          maxHeight={{ base: "inherit", md: "56px" }}
          minWidth={{ base: "inherit", md: "56px" }}
          maxWidth={{ base: "inherit", md: "56px" }}
          borderRadius="80px"
          alignSelf={{ base: "center", md: "flex-end" }}
          _groupHover={{
            bgColor: { base: "grey-0", md: "white" }
          }}
        >
          <ShiIcon
            name="arrow-right-white-compact"
            sx={{
              path: {
                fill: "white",
              },
            }}
            _groupHover={{
              path: { base: { fill: "white" }, md: { fill: "grey-0" }}
            }}
          />

        </Button>
      );
    };

    const renderBadge = () => {
      if (!badgeLabel) {
        return null;
      }
      return (
        <Flex
          backgroundColor={badgeColor === "yellow" ? "yellow-0" : "blue-5"}
          color="grey-0"
          fontSize={{ base: "font-10", md: "15px" }}
          lineHeight={{ base: "1", md: "base" }}
          borderRadius={{ base: "md", md: "6px" }}
          paddingX="space-8"
          paddingY={{ base: "space-6", md: "space-4" }}
          marginBottom={{ base: "0", md: "space-16" }}
          marginTop={{ base: "space-8", md: "0" }}
          columnGap="space-4"
          alignSelf="flex-start"
        >
          {imageFile ? (
            <Center
              flexShrink="0"
              h={{ base: "10px", md: "16px" }}
              w={{ base: "10px", md: "16px" }}
              marginTop={{ base: "0px", md: "2px" }}
            >
              <SvgOrImg
                imageFile={imageFile}
                size={isMobile ? 10 : 16}
              />
            </Center>
          ) : null}
          <Box>
            {badgeLabel}
          </Box>
        </Flex>
      );

    }

    return (
      <Flex
        key={`${title}-${subtitlePrefix}-${subtitlePrice}-${subtitleSuffix}`}
        backgroundColor="grey-0"
        color="white"
        fontFamily="body"
        fontWeight="normal"
        textAlign="left"
        padding={{ base: "space-16", md: "space-32" }}
        flex="1"
        borderRadius="lg"
        direction="column"
        cursor="pointer"
        role="group"
        onClick={() => {
          if (link?.url) {
            navigate(link.url);
          }
        }}
      >
        {isMobile ? null : renderBadge()}
        <Flex
          w="full"
          direction="row"
          justifyContent="space-between"
        ><Box
          fontSize={{ base: "font-20", md: "font-28" }}
          lineHeight="short"
        >
            {title}
          </Box>
          {isMobile ? renderButton() : null}
        </Flex>
        <Box
          fontSize="font-28"
          lineHeight="short"

          marginBottom="space-16"
          display={{ base: "none", md: "block" }}
        >
          {applyNbsp(`${subtitlePrefix ?? ""} ${subtitlePrice} ${subtitleSuffix ?? ""}`.replace(/ {2,}/g, " "))}
        </Box>
        {subtitlePrice ? <Box
          fontSize="font-28"
          lineHeight="26px"
          marginTop="space-8"
          marginBottom={{ base: "space-8", md: "space-16" }}
          display={{ base: "block", md: "none" }}
        >
          {subtitlePrefix ? <Box display="inline"
            fontSize="font-15"
            lineHeight="base"
          >{`${subtitlePrefix?.trim()} `}</Box> : null}
          <Box
            fontSize="font-28"
            lineHeight="26px"
            display="inline">{subtitlePrice.replace(/ {2,}/g, " ")}</Box>
          <Box display="inline"
            fontSize="font-15"
            lineHeight="base"
          >{` ${subtitleSuffix?.trim()}`}</Box>
        </Box> : null}
        <Flex
          w="full"
          direction="row"
          justifyContent="space-between"
          flexGrow="1"
          alignSelf="flex-end"
        >
          <Box
            fontSize={{ base: "font-15", md: "font-18" }}
            lineHeight="base"
            maxWidth={isMobile ? "75%" : (hasAtLeast3Items ? "100%" : "55%")}
            alignSelf="flex-end"
          >
            {description}
          </Box>
          {isMobile ? null : renderButton()}
        </Flex>
        {isMobile ? renderBadge() : null}
      </Flex>

    )
  }

  const renderItemsGroup = (itemsGroup: Gatsby.PageBlockPricingLightFragment["items"]) => {
    const hasAtLeast3Items = (itemsGroup?.filter((item) => !!item).length ?? 0) > 2;
    return (
      <Flex
        direction={{ base: "column", md: "row" }}
        gap="space-16"
        minWidth="100%"
      >
        {itemsGroup?.map((item) => renderItem(item, hasAtLeast3Items))}
      </Flex>
    )
  };

  return (
    <BlockContainer
    >
      <VStack
        gap="space-16"
        minWidth="100%"
      >
        <Box
          width={{ base: "100%", xl: "40%" }}
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    textAlign: { base: "left", md: "center" },
                    fontSize: { base: "font-30", md: "38px", lg: "48px" },
                    lineHeight: 1.2
                  },
                })
            }}
          />
        </Box>
        <HStack
          minWidth="100%"
          display={{ base: "none", md: "flex" }}
          marginTop="0px !important"
        >
          {renderItemsGroup(itemsFirstGroup)}
        </HStack>
        <HStack
          minWidth="100%"
          display={{ base: "none", md: "flex" }}
          marginTop="0px !important"
        >
          {renderItemsGroup(itemsSecondGroup)}
        </HStack>
        <Flex
          display={{ base: "flex", md: "none" }}
          width="100%"
        >
          {renderItemsGroup(items)}
        </Flex>
      </VStack>
    </BlockContainer>
  );
}

export default BlockPricingLight;
