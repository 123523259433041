import { Box, Center, Flex, HStack, useBreakpointValue, Container, keyframes } from "@chakra-ui/react";

import { FC, useEffect, useRef, useState } from "react";
import Renderer from "src/lib/renderer";

import { getBackgroundColor } from "./utils";
import { SvgOrImg } from "src/components/SvgOrImg";

// Hardcoded values to reflect the same speed as Proof Headband current usage
const ROW_1_TIME = '39333ms';
const ROW_2_TIME = '37s';
const DUPLICATION_COUNT = 4;

// space-16 and 4 duplicates => 16/4 => 4px
const moveDesktopRL = keyframes`
  to {transform: translateX(calc(-25% - 4px)) translateZ(0)} 
`;
const moveMobileRL = keyframes`
  to {transform: translateX(calc(-25% - 4px)) translateZ(0)} 
`;

const moveDesktopLR = keyframes`
  to {transform: translateX(calc(25% + 4px)) translateZ(0)} 
`;
const moveMobileLR = keyframes`
  to {transform: translateX(calc(25% + 4px)) translateZ(0)} 
`;

const BlockUSP: FC<Gatsby.PageBlockUSPFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  if ((items?.length ?? 0) <= 0) {
    throw Error();
  }

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const {
    content
  } = primary;
  const refSecondGroup = useRef(null);

  const [widthSecondGroupDesktop, setWidthSecondGroupDesktop] = useState(0);
  const [widthSecondGroupMobile, setWidthSecondGroupMobile] = useState(0);

  const itemsFirstGroup = items?.length ? items.filter((i) => !i?.is_second_row) : [];
  const itemsSecondGroup = items?.length ? items.filter((i) => !!i?.is_second_row) : [];

  const setWidthsCallback = () => {
    if (isMobile) {
      if (refSecondGroup.current?.scrollWidth > widthSecondGroupMobile) {
        setWidthSecondGroupMobile(refSecondGroup.current?.scrollWidth);
      }
    } else {
      if (refSecondGroup.current?.scrollWidth > widthSecondGroupDesktop) {
        setWidthSecondGroupDesktop(refSecondGroup.current?.scrollWidth);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", setWidthsCallback);
    return () => {
      window.removeEventListener("resize", setWidthsCallback);
    };
  }, []);

  useEffect(() => {
    setWidthsCallback()
  }, [refSecondGroup, itemsSecondGroup]);

  const renderItem = (item: NonNullable<Gatsby.PageBlockUSPFragment["items"]>[number], indexDuplicate: number) => {
    if (!item) {
      return null;
    }

    const {
      background_color: backgroundColor,
      label,
      icon
    } = item;

    const imageFile = icon?.document?.data?.image_file;

    return (
      <Box
        key={`${label}-${backgroundColor}-${indexDuplicate}`}
        backgroundColor={getBackgroundColor(backgroundColor)}
        color={backgroundColor === "black" ? "white" : "grey-0"}
        fontFamily="body"
        fontWeight="normal"
        fontSize={{ base: "20px", md: "28px" }}
        lineHeight={{ base: "base", md: "short" }}
        paddingX="space-32"
        paddingY={{ base: "space-16", md: "space-24" }}
        borderRadius="lg"
        minHeight={{ base: "62px", md: "150px" }}
        maxHeight={{ base: "62px", md: "150px" }}
        whiteSpace="nowrap"
      >
        <Center
          h={{ base: "31px", md: "102px" }}
        >
          <HStack
            spacing="space-16"
          >

            {imageFile ? (
              <Center
                flexShrink="0"
                h={{ base: "20px", md: "28px" }}
                w={{ base: "20px", md: "28px" }}
                marginTop="-4px"
              >
                <SvgOrImg
                  imageFile={imageFile}
                  size={isMobile ? 20 : 28}
                  colorChanges={backgroundColor === "black" ? [["#25241D", "white"]] : []}
                  includeAntiFlickering={true}
                />
              </Center>
            ) : null}
            <Box>
              {label}
            </Box>

          </HStack>
        </Center>
      </Box>
    )
  };

  const renderItemsGroup = (itemsGroup: Gatsby.PageBlockUSPFragment["items"], index: number) => {
    const isFirstGroup = index === 0;
    const duration = isFirstGroup ? ROW_1_TIME : ROW_2_TIME;
    const moveMobile = isFirstGroup ? moveMobileRL : moveMobileLR;
    const moveDesktop = isFirstGroup ? moveDesktopRL : moveDesktopLR;
    const horizontalTotalLength = isMobile ? widthSecondGroupMobile : widthSecondGroupDesktop;
    const horizontalAlignmentProps = isFirstGroup ? {} : {
      marginLeft: horizontalTotalLength > 0 ? `-${parseInt(horizontalTotalLength / DUPLICATION_COUNT, 10).toString()}px` : '0px'
    }

    return (
      <Flex
        columnGap="space-16"
        {...horizontalAlignmentProps}
        animation={{ base: `${moveMobile} ${duration} infinite linear`, md: `${moveDesktop} ${duration} infinite linear` }}
      >
        {itemsGroup?.map((item) => renderItem(item, 0))}
        {itemsGroup?.map((item) => renderItem(item, 1))}
        {itemsGroup?.map((item) => renderItem(item, 2))}
        {itemsGroup?.map((item) => renderItem(item, 3))}
      </Flex>
    )
  };

  return (
    <Container size="full"
      maxWidth={{ base: "100%", "2xl": "100%" }}
      margin="0"
    >
      <Flex
        gap="space-16"
        maxWidth="100%"
        overflow="hidden"
        position="relative"
        direction="column"
      >
        <Renderer
          field={content}
        />
        <Flex
        >
          {renderItemsGroup(itemsFirstGroup, 0)}
        </Flex>
        <Flex
          ref={refSecondGroup}

        >
          {renderItemsGroup(itemsSecondGroup, 1)}
        </Flex>
      </Flex>
    </Container>
  );
}

export default BlockUSP;
