import { Box, Center, Flex, Text, keyframes, useBreakpointValue } from "@chakra-ui/react";

import { FC } from "react";
import Renderer from "src/lib/renderer";

import { SvgOrImg } from "src/components/SvgOrImg";
import RatingsGradeStars from "src/components/GradeStars";

// space-24 and 4 duplicates => 24/4 => 6px
const moveDesktop = keyframes`
  to {transform: translateX(calc(-25% - 6px)) translateZ(0)} 
`;
const moveMobile = keyframes`
  to {transform: translateX(-25%) translateZ(0)} 
`;

const BlockProofHeadband: FC<Gatsby.PageBlockProofHeadbandFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  if ((items?.length ?? 0) <= 0) {
    throw Error();
  }

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const {
    content
  } = primary;

  const renderItem = (item: NonNullable<Gatsby.PageBlockProofHeadbandFragment["items"]>[number], indexDuplicate: number, isLastItem: boolean) => {
    const {
      rating,
      label,
      icon
    } = item;

    const imageFile = icon?.document?.data?.image_file;

    if (imageFile) {
      return (
        <Box
          paddingX={{ base: "38px", md: "space-48" }}
          paddingY={{ base: "space-24", md: "space-32" }}
          height={{ base: "84px", md: "134px" }}
          key={`${imageFile?.gatsbyImageData?.images?.fallback?.src}-${indexDuplicate}`}
        >
          <Center
            flexShrink="0"
            h={{ base: "42px", md: "67px" }}
            w={{ base: "42px", md: "67px" }}

          >
            <SvgOrImg
              imageFile={imageFile}
              size={50}
              isBox={true}
              includeAntiFlickering={true}
            />
          </Center>
        </Box>
      )
    }

    return (
      <Box
        height={{ base: "84px", md: "134px" }}
        paddingX={{ base: "25px", md: "space-32" }}
        paddingY={{ base: "12px", md: "space-32" }}
        textAlign="left"
        color="grey-0"
        key={`${rating}-${label}-${indexDuplicate}`}

      >
        <Flex
          flexDirection="row"
          height="100%"
          gap="space-16"

        >
          {rating ? <Text
            fontWeight="400"
            color="grey-0"
            fontSize={{ base: "44px", md: "55px" }}
            lineHeight={{ base: 1.27, md: 1.27 }}
          >
            {rating?.toString().replace(/\./g, ',')}
          </Text> : null}
          <Box
            marginBottom={{ base: "12px", md: "10px" }}
            alignSelf="center"
          >
            {label ? <Text
              fontWeight="400"
              fontSize={{ base: "font-16", md: "font-20" }}
              lineHeight={{ base: "base", md: "base" }}
              marginBottom="0"
              paddingBottom="0"
              whiteSpace="nowrap"
            >
              {label}
            </Text> : null}
            {rating ? <RatingsGradeStars
              fill="grey-0"
              fillPlaceholder="greyLight-1"
              value={rating}
              spacing="0.375rem"
              size={isMobile ? "space-12" : "space-16"}
              marginTop="space-4"
              paddingTop="0"
            /> : null}
          </Box>
        </Flex>
      </Box>
    )
  };

  const renderItems = (items: Gatsby.PageBlockProofHeadbandFragment["items"]) => {
    return (
      <Flex
        columnGap={{ base: "0", md: "space-24" }}
        animation={{ base: `${moveMobile} 30s infinite linear`, md: `${moveDesktop} 30s infinite linear` }}
      >
        {items?.map((item, index) => renderItem(item, 0, items?.length - 1 === index))}
        {items?.map((item, index) => renderItem(item, 1, items?.length - 1 === index))}
        {items?.map((item, index) => renderItem(item, 2, items?.length - 1 === index))}
        {items?.map((item, index) => renderItem(item, 3, items?.length - 1 === index))}
      </Flex>
    )
  };

  return (
    <Flex
      maxWidth="100%"
    >
      <Renderer
        field={content}
      />
      <Flex
        overflow="hidden"
      >
        {renderItems(items)}
      </Flex>
    </Flex>
  );
}

export default BlockProofHeadband;
