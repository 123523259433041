const getBackgroundColor = (backgroundColor?: string | null) => {
  switch (backgroundColor) {
    case "blue":
      return "blue-1";

    case "orange":
      return "orange-500";

    case "black":
      return "grey-0";

    case "yellow":
      return "yellow-0";

    case "green":
      return "green-500";

    case "grey":
    default:
      return "grey-200";
  }
};

export {
  getBackgroundColor
};
